import { combineReducers } from 'redux';
import patientReducer from './patientReducer';
import authReducer from './authReducer';
import overviewReducer from './overviewReducer';
import adminReducer from './adminReducer';


const rootReducer = combineReducers({
  patient: patientReducer,
  auth: authReducer,
  overview: overviewReducer,
  admin: adminReducer,
});

export default rootReducer;
