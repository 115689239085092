import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';

import { loginAction } from '../../redux/actions/authActions';


const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});

const initialValues = {
  email: '',
  password: '',
};

const Login = ({ history }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const errorMessage = useSelector(state => state.auth.errorMessage);
  const dispatch = useDispatch();

  const onSubmit = async (values, { setSubmitting }) => {
    const result = await dispatch(loginAction(values));
    if (result) {
      setSubmitting(false);
    }
  };

  if (isAuthenticated) {
    history.push('/dashboard');
  }

  return (
    <div className='login'>
      <section>
        <Typography className='login__title' variant='h4' component='h4'>
          Sign in
    </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={SigninSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                className='login__input'
                component={TextField}
                name='email'
                type='email'
                label='Email'
              />
              <Field
                className='login__input'
                component={TextField}
                name='password'
                type='password'
                label='Password'
              />
              <div className='login__submit'>
                {errorMessage &&
                  <Typography className='login__error-message'>{errorMessage}</Typography>
                }
                <Button className='login__button' type='submit' variant='contained' disabled={isSubmitting}>Sign in</Button>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Login;
