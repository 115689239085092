import React from 'react';
import {
  Link,
  Button,
} from '@material-ui/core';

import { API_DOMAIN, ORDER } from '../../constant';
import DashboardContent from '../../components/DashboardContent';



export default function Data() {
  return (
    <DashboardContent title="Data">
      <h2>Participants</h2>
      <Link href={`${API_DOMAIN}/admin/patients/csv-data`}>
        Download all participants
      </Link>
      <br/>
      <Link href={`${API_DOMAIN}/admin/heart-checks/csv`}>
        Download all heart checks
      </Link>
      <br/>
      <Link href={`${API_DOMAIN}/admin/wellness-checks/csv`}>
        Download all wellness checks
      </Link>
      <br/>
      <Link href={`${API_DOMAIN}/admin/event-profiles/csv`}>
        Download all event profiles
      </Link>
      <br/>
      <Link href={`${API_DOMAIN}/admin/participant-app-usage/csv`}>
        Download participant app usage
      </Link>
      <h2>Data Donations</h2>
      <Link href={`${API_DOMAIN}/admin/data-donations/all/csv`}>
        Download All
      </Link>
      <br/>
      <Link href={`${API_DOMAIN}/admin/data-donations/socio-economics/csv`}>
        Download Socio-Economics
      </Link>
      <br/>
      <Link href={`${API_DOMAIN}/admin/data-donations/demographic-factors/csv`}>
        Download Demographic Factors
      </Link>
      <br/>
      <Link href={`${API_DOMAIN}/admin/data-donations/gynecological-obstetrics-history/csv`}>
        Download Gynecological/Obstetrics History
      </Link>
      <br/>
      <Link href={`${API_DOMAIN}/admin/data-donations/identity-and-society/csv`}>
        Download Identity and Society
      </Link>
      <br/>
      <Link href={`${API_DOMAIN}/admin/data-donations/medications/csv`}>
        Download Medications
      </Link>
      <br/>
      <Link href={`${API_DOMAIN}/admin/data-donations/other-conditions/csv`}>
        Download Other Conditions
      </Link>
      <h2>Misc.</h2>
      <Link href={`${API_DOMAIN}/admin/library-usage/csv`}>
        Download Library Usage
      </Link>
    </DashboardContent>
  );
}
