import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { logoutAction } from '../../redux/actions/authActions';


const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      await dispatch(logoutAction());
      window.location.reload();
    })();
  }, []);
  return null;
};

export default Logout;
