import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  ButtonGroup,
  Typography,
} from '@material-ui/core';
import { loadResearchers, ResearcherOption } from '../../redux/actions/adminActions';
import DashboardContent from '../../components/DashboardContent';
import HPModal from '../../components/HPModal';

const ADMINOPTION = {
  resend: {
    option: 'resend',
    text: 'Resend Token',
  },
  cancel: {
    option: 'cancel',
    text: 'Cancel Invite',
  },
  remove: {
    option: 'remove',
    text: 'Remove',
  }
};

const POSSIBLEOPTIONS = {
  invited: ['resend', 'cancel'],
  token_expired: ['resend', 'cancel'],
  registered: ['remove'],
};

export default function Researchers() {
  const { list, columns, total } = useSelector(state => state.admin);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [modalState, setModalState] = useState({
    content: '',
    text: '',
    buttonText: '',
    adminOption: {},
  });

  const handleOption = (option, researcher) => {
    setOpenModal(true);
    setModalState({
      ...modalState,
      content: `Do you want to ${option} ${researcher.email}`,
      text: 'Cancel',
      buttonText: 'Confirm!',
      adminOption: {
        option: option,
        id: researcher.id
      },
    });
  }

  const handleModalButton = async () => {
    let newContent = '';
    const error = await dispatch( ResearcherOption(modalState.adminOption) );
    if ( error ) {
      newContent = error;
    } else {
      newContent = 'Success';
      setTimeout(() => (
        history.go(0)
      ), 1000 );
    }
    setModalState({
      ...modalState,
      content: newContent,
      text: '',
      buttonText: '',
    });
  }

  useEffect(() => {
    dispatch(loadResearchers());
  }, []);

  return (
    <DashboardContent title="Researchers">
      <div className="researchers__page">
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((c, idx) => (
                  <TableCell key={c.id} align={idx === columns.length - 1 ? 'right' : 'left'}>
                    {c.text}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.email}</TableCell>
                  <TableCell className='researchers__page--registration'>{row.registration}</TableCell>
                  <TableCell align='right'>
                    <ButtonGroup disableElevation variant="contained" color="primary" size="small">
                      { POSSIBLEOPTIONS[row.registration] &&
                        POSSIBLEOPTIONS[row.registration].map((o, idx) => (
                          <Button
                            key={idx}
                            className='researchers__page--button'
                            variant='contained'
                            onClick={() => handleOption(ADMINOPTION[o].option, row)}
                            size='small'
                          >{ADMINOPTION[o].text}</Button>
                        ))
                      }
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant='button' display='block' align='right' className='researchers__page--total'>
          Total: {total}
        </Typography>
      </div>
      <HPModal
        open={openModal}
        onClose={()=>setOpenModal(false)}
        title='Confirm Option'
        buttonText={modalState.buttonText}
        buttonOnClick={handleModalButton}
        className='data-donation__modal'
        text={modalState.text}
        textOnClick={()=>setOpenModal(false)}
      >
        {modalState.content}
      </HPModal>
    </DashboardContent>
  );
}
