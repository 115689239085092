import React, { useEffect, useState, useRef } from 'react';
import DashboardContent from '../../components/DashboardContent';
import HeartCheckStatusHeader from '../../components/HeartCheckStatusHeader';
import WellnessCheckCardContent from '../../components/WellnessCheckCardContent'
import HPModal from '../../components/HPModal';
import { useSelector } from 'react-redux';
import { Redirect, useLocation, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { loadPatientData, fetchIndividualPatientCSV } from '../../redux/actions/patientActions';
import {
  activatePatientById,
  deactivatePatientById,
  getPatientDataById,
  deletePatientDataById,
  getPatientHeartChecksByStudyId,
  getPatientWellnessChecksByStudyId,
  getPatientNotesByStudyId,
} from '../../services/heartPainApi/patientService';
import {
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import { CSVLink } from 'react-csv';
import HeartCheckCard from '../../components/HeartCheckCard';


const ProfileHeaderMap = {
  discomfort: 'Characteristics of pain/discomfort',
  location: 'Pain/Discomfort from',
  travel: 'Pain/Discomfort to',
  symptoms: 'Symptoms',
}

const SIZE = 5;

const PatientStatusMap = {
  ACTIVATED: 'Active',
  DEACTIVATED: 'Inactive',
  OPEN: 'Open',
  VERIFYING: 'Verifying',
};

const Patient = () => {
  const { studyId } = useParams();
  const history = useHistory();

  const [patientEventProfile, setPatientEventProfile] = useState([]);
  const [patientWellnessCheck, setPatientWellnessCheck] = useState([]);
  const [currentStudyId, setCurrentStudyId] = useState(-1);
  const [patientHeartCheck, setPatientHeartCheck] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [patient, setPatient] = useState({});

  const [heartCheckColumn, setHeartCheckColumn] = useState([]);
  const [heartCheckList, setHeartCheckList] = useState([]);
  const [heartCheckTotal, setHeartCheckTotal] = useState(0);
  const [heartCheckPage, setHeartCheckPage] = useState(0);

  const [wellnessCheckColumn, setWellnessCheckColumn] = useState([]);
  const [wellnessCheckList, setWellnessCheckList] = useState([]);
  const [wellnessCheckTotal, setWellnessCheckTotal] = useState(0);
  const [wellnessCheckPage, setWellnessCheckPage] = useState(0);

  const [notesColumn, setNotesColumn] = useState([]);
  const [notesList, setNotesList] = useState([]);
  const [notesTotal, setNotesTotal] = useState(0);
  const [notesPage, setNotesPage] = useState(0);

  const [exportData, setExportData] = useState([]);
  const [exportHeaders, setExportHeaders] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const csvLink = useRef();

  useEffect(() => {
    if (isExporting) {
      handleExportCSV();
    }
  }, [isExporting]);

  useEffect(() => {
    (async () => {
      if (studyId === currentStudyId) {
        return;
      }

      const patientData = await getPatientDataById(studyId);

      if (patientData) {
        setPatient(patientData.patient);
        setPatientHeartCheck(patientData.heartCheck);
        setPatientEventProfile(patientData.eventProfile);
        setPatientWellnessCheck(patientData.wellnessCheck);
        setCurrentStudyId(patientData.studyId);
      }
    })()
  }, [studyId]);

  useEffect(() => {
    (async () => {
      const heartChecks = await getPatientHeartChecksByStudyId(studyId, { page: heartCheckPage, size: SIZE });
      if (heartChecks) {
        setHeartCheckColumn(heartChecks.columns);
        setHeartCheckList(heartChecks.heartCheckData);
        setHeartCheckTotal(heartChecks.total);
      }
    })()
  }, [heartCheckPage]);

  useEffect(() => {
    (async () => {
      const wellnessChecks = await getPatientWellnessChecksByStudyId(studyId, { page: wellnessCheckPage, size: SIZE });
      if (wellnessChecks) {
        setWellnessCheckColumn(wellnessChecks.columns);
        setWellnessCheckList(wellnessChecks.wellnessCheckData);
        setWellnessCheckTotal(wellnessChecks.total);
      }
    })()
  }, [wellnessCheckPage]);

  useEffect(() => {
    (async () => {
      const notes = await getPatientNotesByStudyId(studyId, { page: notesPage, size: SIZE });
      if (notes) {
        setNotesColumn(notes.columns);
        setNotesList(notes.notesData);
        setNotesTotal(notes.total);
      }
    })()
  }, [notesPage]);

  const handleExportCSV = () => {
    fetchIndividualPatientCSV(studyId).then(async data => {
      await setExportData(data.data);
      await setExportHeaders(data.headers);
      csvLink.current.link.click();
    })
  }

  const dateFormat = (date) => {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    }
  };

  const handleHeartCheck = (id) => {
    history.push('/dashboard/heart-check/' + id);
  }

  const handleHeartCheckChangePage = (event, newPage) => {
    setHeartCheckPage(newPage);
  };

  const handleWellnessCheck = (id) => {
    history.push('/dashboard/wellness-check/' + id);
  }

  const handleWellnessCheckChangePage = (event, newPage) => {
    setWellnessCheckPage(newPage);
  }

  const handlePatientDelete = async () => {
    await deletePatientDataById(studyId);
    history.push('/dashboard/participants');
  }

  const handleNotesChangePage = (event, newPage) => {
    setNotesPage(newPage);
  };

  const activatePatient = async () => {
    const proceed = window.confirm(`Are you sure you want to activate participant ${patient.studyId}?`);
    if (proceed && patient.studyId) {
      await activatePatientById(patient.studyId);
      window.location.reload();
    }
  };

  const deactivatePatient = async () => {
    const proceed = window.confirm(`Are you sure you want to deactivate participant ${patient.studyId}?`);
    if (proceed && patient.studyId) {
      await deactivatePatientById(patient.studyId);
      window.location.reload();
    }
  };

  return (
    <DashboardContent title={'Study Id: ' + studyId}>
      <br/>
      <Typography><strong>Email:</strong> {patient.email}</Typography>
      <Typography><strong>Status:</strong> {PatientStatusMap[patient.status] ?? ''}</Typography>
      {<HeartCheckStatusHeader status={patientHeartCheck.status} createdAt={patientHeartCheck.date} />}
      {/*
      <div className="patient__page--function-list">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsExporting(true)}
        >
          Export CSV
        </Button>
        <CSVLink
          data={exportData}
          headers={exportHeaders}
          filename={`${studyId}.csv`}
          className="hidden"
          ref={csvLink}
          onClick={() => setIsExporting(false)}
        />
      </div>
      */}
     <Grid container spacing={2}>
       <Grid item xs={12}>
         {patient.status === 'ACTIVATED' && (
           <Button
            color='primary' variant="contained"
            onClick={deactivatePatient}
           >
            Deactivate
          </Button>
         )}
         {patient.status === 'DEACTIVATED' && (
           <Button
            color='primary' variant="contained"
            onClick={activatePatient}
           >
            Activate
          </Button>
         )}
      </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card className='card' variant="outlined">
            <CardContent>
              <Typography variant='h5' className='eventprofile__header'><strong>Latest Event Profile</strong></Typography>
              {patientEventProfile.length === 0 &&
                <Typography className='eventprofile__item eventprofile__header'>The user hasn’t completed their Event Profile yet.</Typography>
              }
              {patientEventProfile && patientEventProfile.map((profileItem) => (
                <div key={profileItem.value} className='eventprofile__item'>
                  <Typography className='eventprofile__content'><strong>{ProfileHeaderMap[profileItem.value]}</strong></Typography>
                  <Typography className='eventprofile__content'>{profileItem.text}</Typography>
                </div>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <HeartCheckCard title="Latest Heart Check" heartCheckProfile={patientHeartCheck} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card className='card' variant="outlined">
            <CardContent>
              <Typography variant='h5' className='eventprofile__header'><strong>Latest Wellness Check</strong></Typography>
              <WellnessCheckCardContent wellnessCheckData={patientWellnessCheck} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <br />
      <Typography variant="h5">
        Heart Checks
      </Typography>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="heart check table">
          <TableHead>
            <TableRow>
              {heartCheckColumn.map((c) => (
                <TableCell key={c.id}>
                  {c.text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {heartCheckList.map((row) => (
              <TableRow key={row.id} onClick={() => handleHeartCheck(row.id)} className='patient__page--clickable-cell'>
                <TableCell>{dateFormat(row.createdAt)}</TableCell>
                <TableCell>{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[3]}
        component="div"
        count={heartCheckTotal}
        rowsPerPage={SIZE}
        page={heartCheckPage}
        onChangePage={handleHeartCheckChangePage}
      />
      <Typography variant="h5">
        Wellness Checks
      </Typography>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="wellness check table">
          <TableHead>
            <TableRow>
              {wellnessCheckColumn.map((c) => (
                <TableCell key={c.id}>
                  {c.text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {wellnessCheckList.map((row) => (
              <TableRow key={row.id} onClick={() => handleWellnessCheck(row.id)} className="patient__page--clickable-cell">
                <TableCell>{dateFormat(row.createdAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[3]}
        component="div"
        count={wellnessCheckTotal}
        rowsPerPage={SIZE}
        page={wellnessCheckPage}
        onChangePage={handleWellnessCheckChangePage}
      />
      <Typography variant="h5">
        Notes
      </Typography>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="notes table">
          <TableHead>
            <TableRow>
              {notesColumn.map((c) => (
                <TableCell key={c.id}>
                  {c.text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {notesList.map((row, idx) => (
              <TableRow key={idx}>
                <TableCell>{dateFormat(row.createdAt)}</TableCell>
                <TableCell>{dateFormat(row.updatedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[3]}
        component="div"
        count={notesTotal}
        rowsPerPage={SIZE}
        page={notesPage}
        onChangePage={handleNotesChangePage}
      />
      <Button
        className='patient__page--delete'
        color='primary' variant="contained"
        startIcon={<DeleteIcon />}
        onClick={() => setOpenModal(true)}
      >
        Delete
      </Button>
      <HPModal
        open={openModal}
        title='Are you sure you want to delete this patient?'
        buttonText='Delete Forever'
        buttonOnClick={handlePatientDelete}
        className='data-donation__modal'
        text="Cancel"
        textOnClick={() => setOpenModal(false)}
        onClose={() => setOpenModal(false)}
      >
        This is not reversible, once deleted they cannot be recovered.
      </HPModal>
    </DashboardContent>
  );
}

export default Patient;
