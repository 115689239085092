import { api } from './api';


async function login(user) {
  try {
    const { data } = await api.post('/auth/admin/login', user);
    return data;
  } catch (e) {
    return {
      error: e?.response?.data?.msg
        ? e.response.data.msg
        : 'Fail to login'
    };
  }
}

function logout() {
  return api.post('/auth/logout');
}

async function authenticated() {
  try {
    const { status, data } = await api.post('/auth/authenticated');
    return ({
      isAuthenticated: status === 200,
      data,
    });
  } catch (e) {
    return false;
  }
}

export {
  authenticated,
  login,
  logout,
};
