import React from 'react';
import {
  Toolbar,
  Typography,
} from '@material-ui/core';


function DashboardContent({ title, children }) {
  return (
    <>
      <Toolbar />
      <Typography variant="h4">{title}</Typography>
      {children}
    </>
  );
}

export default DashboardContent;
