import { getPatients, getPatientDataById, getAllPatientsList, getPatientsCSVByStudyId } from '../../services/heartPainApi/patientService';

export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENT_DATA = 'GET_PATIENT_DATA';
export const GET_ALL_PATIENTS_CSV = 'GET_ALL_PATIENTS_CSV';

export function loadPatients({ page, size, filter, orderBy, order }) {
  return async dispatch => {
    const payload = await getPatients({ page, size, filter, orderBy, order });
    dispatch({ type: GET_PATIENTS, payload });
  };
}

export function loadPatientData( studyId ) {
  return async dispatch => {
    const payload = await getPatientDataById(studyId);
    dispatch({ type: GET_PATIENT_DATA, payload });
  };
}

export function fetchPatientsCSV() {
  return getAllPatientsList();
}

export function fetchIndividualPatientCSV( studyId ) {
  return getPatientsCSVByStudyId(studyId);
}
