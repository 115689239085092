import { getOverview } from '../../services/heartPainApi/overviewService';

export const GET_OVERVIEW = 'GET_OVERVIEW';

export function loadOverview() {
  return async dispatch => {
    const payload = await getOverview();
    dispatch({ type: GET_OVERVIEW, payload });
  };
}
