import React from 'react';
import {
  Typography,
  Link,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export default function RegisterSuccess() {
  const history = useHistory();
  const backLogin = () => {
    history.push('/account/login');
  }

  return (
    <div className="login">
      <Typography variant='h5'>
        Welcome to at heart!
      </Typography>
      <Typography>
        <Link onClick={backLogin}>Click here to go back to Login page</Link>
      </Typography>
    </div>
  );
}
