import axios from 'axios';

import { API_DOMAIN } from '../../constant';


const _api = axios.create({
  baseURL: API_DOMAIN,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const api = _api;
export default api;
