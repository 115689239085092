import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
} from '@material-ui/core';


export default ({ title, children, ...props }) => (
  <div {...props}>
    <AppBar position="fixed" className="main-app-bar">
      <Toolbar>
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
    {children}
  </div>
);
