import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import * as Yup from 'yup';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { registerResearcherAction } from '../../redux/actions/adminActions';
import { getEmailByRegisterToken } from '../../services/heartPainApi/auth';



const RegisterSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, 'Password must be at least 3 characters long')
    .required('Required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});


const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const errorMessage = useSelector(state => state.admin.errorMsg);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  if (isAuthenticated) {
    history.push('/dashboard');
    return null;
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');

  if (!token) {
    history.push('/account/login');
    return null;
  }

  const onSubmit = async (values) => {
    const result = await dispatch(registerResearcherAction(values.password, token));
    if ( !result ) {
      history.push('/account/register/success');
      return null;
    }
  };

  return (
    <div className='register'>
      <section>
        <Typography className='login__title' variant='h4' component='h4'>
          New Researcher Register
        </Typography>
        <Formik
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
          validationSchema={RegisterSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                className='login__input'
                component={TextField}
                name='password'
                type={showPassword ? 'text' : 'password'}
                label='New Password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Field
                className='login__input'
                component={TextField}
                name='passwordConfirm'
                type={showPassword ? 'text' : 'password'}
                label='Confirm password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className='login__submit'>
                {errorMessage &&
                  <Typography className='login__error-message'>{errorMessage}</Typography>
                }
                <Button className='login__button' type='submit' variant='contained' color='secondary' disabled={isSubmitting}>Register</Button>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </div>
  );
}

export default Register;
