import React from 'react';
import moment from 'moment';
import {
  Typography,
} from '@material-ui/core';

const WellnessCheckCardContent = ({ wellnessCheckData, createdAt }) => (
  <div>
    {(!wellnessCheckData || wellnessCheckData.length === 0) &&
      <Typography className='eventprofile__item eventprofile__header'>This wellness check profile does not exist yet.</Typography>
    }
    {wellnessCheckData && wellnessCheckData.map((wellnessItem) => (
      <div key={wellnessItem.title} className='eventprofile__item'>
        <Typography className='eventprofile__content--zero-tp-padding'><strong>{wellnessItem.title}</strong>: {wellnessItem.value}</Typography>
      </div>
    ))}
    {createdAt &&
      <div className='eventprofile__item'>
        <Typography className='eventprofile__content--zero-tp-padding'>
          Created At: {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      </div>
    }
  </div>
)

export default WellnessCheckCardContent
