import React from 'react';
import {
  Typography,
  Link,
} from '@material-ui/core';
import DashboardContent from '../../components/DashboardContent';
import { useHistory } from 'react-router-dom';

export default function NewResearcherSuccess() {
  const history = useHistory();
  const backOverview = () => {
    history.push('/dashboard/overview');
  }

  return (
    <DashboardContent title="Success" className="overview-page">
      <Typography variant='h5'>
        You’ve added a new researcher.
      </Typography>
      <Typography>
        <Link onClick={backOverview}>Click here to go back to Overview page</Link>
      </Typography>
    </DashboardContent>
  );
}
