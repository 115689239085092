import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from './Login';
import Logout from './Logout';
import Register from './Register';
import RegisterSuccess from './RegisterSuccess';
import ProtectedRoute from '../../components/ProtectedRoute';
import UnprotectedRoute from '../../components/UnprotectedRoute';

const Auth = () => (
  <main id="main" className="auth__content">
    <Switch>
      <UnprotectedRoute path="/account/register/success" component={RegisterSuccess} />
      <UnprotectedRoute path="/account/register" component={Register} />
      <UnprotectedRoute path="/account/login" component={Login} />
      <ProtectedRoute path="/account/logout" component={Logout} />
    </Switch>
  </main>
);

export default Auth;
