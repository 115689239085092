import React from 'react';
import moment from 'moment';
import {
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';

import HeartCheckStatusHeader from './HeartCheckStatusHeader';


const HeartCheckCard = ({ heartCheckProfile = {}, title = 'Heart Check Profile'}) => {

  if (Object.keys(heartCheckProfile) === 0) {
    return null;
  }

  return (
    <Card className='card' variant="outlined">
      <CardContent>
        <Typography variant='h5' className='eventprofile__header'><strong>{title}</strong></Typography>
        <div className='eventprofile__item'>
          {heartCheckProfile.status ?
            <HeartCheckStatusHeader status={heartCheckProfile.status} createdAt={heartCheckProfile.date} />
            :
            <Typography className='eventprofile__item eventprofile__header'>This heart check profile does not exist yet.</Typography>
          }
        </div>
        {heartCheckProfile.data && heartCheckProfile.data.map((profileItem) => (
          <div key={profileItem.title} className='eventprofile__item'>
            <Typography className='eventprofile__content' ><strong>{profileItem.title}</strong></Typography>
            <Typography className='eventprofile__content'>{profileItem.text}</Typography>
          </div>
        ))}
        {heartCheckProfile.er &&
          <div key='er-status' className='eventprofile__item'>
            <Typography className='eventprofile__content' ><strong>ER Status</strong></Typography>
            <Typography className='eventprofile__content'>
              {(heartCheckProfile.er === 'er-currently') && 'Patient is currently in the ER'}
              {(heartCheckProfile.er === 'er-done') && 'Patient visited the ER'}
              {(heartCheckProfile.data.er === 'er-omw') && 'Patient is on the way to the ER'}
              {(heartCheckProfile.er === 'er-no') && 'Patient did not visit the ER'}
            </Typography>
          </div>
        }
      </CardContent>
    </Card>
  );
};

export default HeartCheckCard;
