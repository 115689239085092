import React from 'react';
import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';


function OverviewStatusCard({ value = 0, text }) {
  return (
    <Card className="overview-page__card" variant="outlined">
      <CardContent>
        <Typography variant="h5" align="center">
          {value}
        </Typography>
        <Typography align="center">
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default OverviewStatusCard;
