import { api } from './api';
import { ADMIN_OPTION } from '../../constant';

async function addNewResearcher(newUserData) {
  try {
    const { data } = await api.post(`/admin/researcher`, newUserData);
    return data;
  } catch (e) {
    return {
      error: e?.response?.data?.msg
        ? e.response.data.msg
        : 'Unknown error, please try again later'
    };
  }
}

async function newResearcherRegister(password, token) {
  try {
    const { data } = await api.post(`/admin/researcher/register`, {
      token: token,
      password,
    });
    return data;
  } catch (e) {
    return {
      error: e?.response?.data?.msg
        ? e.response.data.msg
        : 'Unknown error, please try again later'
    };
  }
}

async function getResearchers() {
  const { data, status } = await api.get(`/admin/researchers`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function performAdminAction( targetOption, user ) {
  const { option, id } = targetOption;
  if ( id && ADMIN_OPTION.includes(option) ) {
    try {
      const { data, status } = await api.post(`/admin/researcher/${id}/${targetOption.option}`, user);
      if ( status === 200 ) {
        return data;
      }
    } catch (e) {
      return {
        error: e?.response?.data?.msg
          ? e.response.data.msg
          : 'Unknown error, please try again later'
      };
    }
  }
  return {error: 'Unknown error, please try again later'};
}

export {
  addNewResearcher,
  newResearcherRegister,
  getResearchers,
  performAdminAction,
};
