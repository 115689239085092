import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableSortLabel,
} from '@material-ui/core';
import moment from 'moment';
import { loadPatients } from '../../redux/actions/patientActions';
import DashboardContent from '../../components/DashboardContent';
import { ORDER } from '../../constant';

const SIZE = 20;

const PatientFilters = {
  all: '',
  hasDonations: 'donation',
  isActive: 'active',
  availableStudyIds: 'available_study_ids',
  isVerifying: 'verifying',
  previousParticipants: 'deactivated',
};

export default function Patients() {
  const { list, columns, total } = useSelector(state => state.patient);
  const user = useSelector(({ auth }) => auth.user);
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = React.useState(PatientFilters.isActive);
  const [order, setOrder] = React.useState(ORDER.ASC);
  const [orderBy, setOrderBy] = React.useState('studyId');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(loadPatients({ page, SIZE, filter, orderBy, order }));
  }, []);
  useEffect(() => {
    setPage(0);
    dispatch(loadPatients({ page: 0, size: SIZE, filter, orderBy, order }));
  }, [filter, order, orderBy])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(loadPatients({ page: newPage, SIZE, filter, orderBy, order }));
  };

  const handlePatient = (studyId) => {
    history.push('/dashboard/participant/' + studyId);
  }

  const handleAddPatient = () => {
    history.push('/dashboard/participants/add');
  }

  const dateFormat = (date) => {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  }

  const handleSortOrder = (property) => (event) => {
    const isAsc = orderBy === property && order === ORDER.ASC;
    setOrder(isAsc ? ORDER.DESC : ORDER.ASC);
    setOrderBy(property);
  }

  return (
    <DashboardContent title="Participants">
      <div className="patient__page">
        <div className="patient__page--function-list">
          {user.role === 'ADMIN' && (
            <Grid>
              <Button
                className="patient__page--button"
                variant="contained"
                color="primary"
                onClick={handleAddPatient}
              >
                Add Study ID
              </Button>
            </Grid>
          )}
          <Grid>
            <FormControl className="patient__page--dropdown">
              <InputLabel shrink id="patient-list-filter-label">Filter</InputLabel>
              <Select
                labelId="patient-list-filter-label"
                id="patient-list-filter-select"
                value={filter}
                onChange={handleFilterChange}
                displayEmpty
              >
                <MenuItem value={PatientFilters.isActive}>Active participants</MenuItem>
                <MenuItem value={PatientFilters.previousParticipants}>Previous participants</MenuItem>
                <MenuItem value={PatientFilters.hasDonations}>Participants with donations</MenuItem>
                <MenuItem value={PatientFilters.isVerifying}>Verifying</MenuItem>
                <MenuItem value={PatientFilters.availableStudyIds}>Available study ids</MenuItem>
                <MenuItem value={PatientFilters.all}>All</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((c) => (
                  <TableCell key={c.id}>
                    <TableSortLabel
                      active={orderBy === c.id}
                      direction={orderBy === c.id ? order : ORDER.ASC}
                      onClick={handleSortOrder(c.id)}
                    >
                      {c.text}
                      {orderBy === c.id ? (
                        <span className='patient__page--visually-hidden'>
                          {order === ORDER.DESC ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => (
                <TableRow key={row.studyId} onClick={() => handlePatient(row.studyId)} className='patient__page--clickable-cell'>
                  <TableCell>{row.studyId}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.current_flow}</TableCell>
                  <TableCell>{dateFormat( row.signup_date )}</TableCell>
                  <TableCell>{dateFormat( row.last_chat_interaction )}</TableCell>
                  <TableCell>{row.heart_status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={total}
          rowsPerPage={SIZE}
          page={page}
          onChangePage={handleChangePage}
        />
      </div>
    </DashboardContent>
  );
}
