import React, { useEffect, useState } from 'react';
import DashboardContent from '../../components/DashboardContent';
import HeartCheckCard from '../../components/HeartCheckCard';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getHeartCheckProfileById } from '../../services/heartPainApi/patientService';


const HeartCheck = () => {
  const { heartCheckId } = useParams();
  const [heartCheckProfile, setHeartCheckProfile] = useState({});

  useEffect(() => {
    (async () => {
      const heartCheckData = await getHeartCheckProfileById(heartCheckId);
      setHeartCheckProfile(heartCheckData);
    })()
  }, [heartCheckId]);

  return (
    <DashboardContent title="Heart Check Profile">
      <br/>
      <HeartCheckCard heartCheckProfile={heartCheckProfile} />
    </DashboardContent>
  );
}

export default HeartCheck;
