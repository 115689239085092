import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CHAT_BOT_DOMAIN } from '../constant';

import { authCheckAction } from '../redux/actions/authActions';


const ProtectedRoute = ({ component: Component, adminOnly = false, ...rest }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const user = useSelector(({ auth }) => auth.user);
  const [authDetermined, setAuthDetermined] = React.useState(isAuthenticated);

  React.useEffect(() => {
    if (!isAuthenticated) {
      dispatch(authCheckAction(() => setAuthDetermined(true)));
    }
  }, [isAuthenticated, dispatch]); // eslint-disable-line

  return (
    authDetermined ? (
      <Route
        {...rest}
        render={props => {
          if (user?.role === 'PATIENT') {
            window.location.href = CHAT_BOT_DOMAIN;
            return null;
          } else if ( (isAuthenticated && !adminOnly) || (isAuthenticated && adminOnly && user.role ==='ADMIN') ) {
            return <Component {...props} />;
          }
          return (
            <Redirect to={
              {
                pathname: '/',
                state: {
                  from: props.location,
                },
              }
            }
            />
          );
        }}
      />
    ) : null
  );
};

export default ProtectedRoute;
