import {
  authenticated,
  login,
  logout,
} from '../../services/heartPainApi/auth';


export const AUTH_AUTHENTICATED_CHECK = 'AUTH_AUTHENTICATED_CHECK';
export const AUTH_LOGIN_USER = 'AUTH_LOGIN_USER';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';

export function loginAction(userData) {
  return async dispatch => {
    const { user, error } = await login(userData);
    if (error) {
      dispatch({ type: AUTH_LOGIN_ERROR, error });
      return error;
    } else {
      dispatch({ type: AUTH_LOGIN_USER, user });
    }
  };
}

export function authCheckAction(cb, userData) {
  return async dispatch => {
    const { isAuthenticated, data } = await authenticated();
    if (isAuthenticated && data) {
      const user = data.user;
      dispatch({ type: AUTH_AUTHENTICATED_CHECK, isAuthenticated, user });
    } else {
      dispatch({ type: AUTH_LOGOUT });
    }
    cb && cb();
  }
}

export function logoutAction() {
  return async dispatch => {
    await logout();
    dispatch({ type: AUTH_LOGOUT });
  };
}
