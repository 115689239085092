import { createMuiTheme } from '@material-ui/core/styles';

const greenColor = '#015e70';
const cherryColor = '#ec008c';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: cherryColor,
    },
    secondary: {
      main: greenColor,
    },
  },
  typography: {
    fontFamily: 'Avenir Next',
    fontSize: 16,

    subtitle1: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
    },
    h4: {
      fontSize: '2.25rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
  },
});

export default theme;
