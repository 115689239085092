import { GET_OVERVIEW } from '../actions/overviewActions';

const initalState = {
  registered: 0,
  articles_favourited: 0,
  recent_active_patients: 0,
  recent_heart_checked: 0,
  latest_green_heart_check_status:0,
  latest_yellow_heart_check_status: 0,
  latest_red_heart_check_status: 0,
};

const patientReducer = (state = initalState, action) => {
  switch (action.type) {
    case GET_OVERVIEW:
      const { registered, articles_favourited, recent_active_patients, recent_heart_checked, latest_green_heart_check_status, latest_yellow_heart_check_status, latest_red_heart_check_status } = action.payload;
      return {
        ...state, registered, articles_favourited, recent_active_patients, recent_heart_checked, latest_green_heart_check_status, latest_yellow_heart_check_status, latest_red_heart_check_status
      };
    default:
      return { ...state };
  }
};

export default patientReducer;
