const API_DOMAIN = process.env.REACT_APP_API_DOMAIN ?? 'http://localhost:8111';
const CHAT_BOT_DOMAIN = process.env.REACT_APP_CHAT_BOT_DOMAIN ?? 'http://localhost:3000';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};
const ADMIN_OPTION = [ 'resend', 'cancel', 'remove' ];

export {
  API_DOMAIN,
  CHAT_BOT_DOMAIN,
  ORDER,
  ADMIN_OPTION,
};

export default {
  API_DOMAIN,
  CHAT_BOT_DOMAIN,
  ORDER,
  ADMIN_OPTION,
};
