import { GET_PATIENTS, GET_PATIENT_DATA } from '../actions/patientActions';

const initalState = {
  list: [],
  columns: [],
  patientEventProfile: [],
  patientWellnessCheck: [],
  patientDataDonation: [],
  patientHeartStatus: '',
  total: 0,
  currentStudyId: -1,
};

const patientReducer = (state = initalState, action) => {
  switch (action.type) {
    case GET_PATIENTS:
      const { patients, columns, total } = action.payload;
      return {
        ...state, list: patients, columns, total,
      };
    case GET_PATIENT_DATA:
      const { eventProfile, wellnessCheck, studyId, dataDonation, heartStatus } = action.payload;
      return {
        ...state,
        patientEventProfile: eventProfile,
        patientWellnessCheck: wellnessCheck,
        currentStudyId: studyId,
        patientDataDonation: dataDonation,
        patientHeartStatus: heartStatus,
      };
    default:
      return { ...state };
  }
};

export default patientReducer;
