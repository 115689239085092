import { api } from './api';

async function getOverview() {
  const { data, status } = await api.get('/researcher/overview');
  if ( status !== 200 ) {
    return null;
  }
  return data;
}

export {
  getOverview,
};
