import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { newResearcherAction } from '../../redux/actions/adminActions';
import DashboardContent from '../../components/DashboardContent';

const ForgetSchema = Yup.object().shape({
  newEmail: Yup.string()
    .email('Invalid email')
    .required('Required'),
  newEmailConfirm: Yup.string()
    .oneOf([Yup.ref('newEmail'), null], 'Email must match'),
  userPassword: Yup.string()
    .min(2, 'Password must be at least 3 characters long')
    .max(50, 'Password not longer than 3 characters')
    .required('Required'),
});

const initialValues = {
  newEmail: '',
  newEmailConfirm: '',
  userPassword: '',
};

export default function NewResearch() {
  const dispatch = useDispatch();
  const errorMessage = useSelector(state => state.admin.errorMsg);

  const history = useHistory();

  const onSubmit = async (values, { setSubmitting }) => {
    const result = await dispatch(newResearcherAction(values));
    if (result) {
      setSubmitting(false);
    } else {
      history.push('/dashboard/researcher/add/success');
    }
  };

  return (
    <DashboardContent title="Add New Research" className="overview-page">
      <Formik
        initialValues={initialValues}
        validationSchema={ForgetSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className='overview-page__new-reseach--form'>
            <Field
              className='overview-page__new-reseach--input'
              component={TextField}
              name='newEmail'
              type='email'
              label='New Researcher Email'
            />
            <Field
              className='overview-page__new-reseach--input'
              component={TextField}
              name='newEmailConfirm'
              type='email'
              label='Confirm email'
            />
            <Field
              className='overview-page__new-reseach--input'
              component={TextField}
              name='userPassword'
              type='password'
              label='Your account password'
            />
            <Typography>
              * Please note that the email link sent to the new researcher will expire in 72 hours.
            </Typography>
            <div className='overview-page__new-reseach--submit'>
              {errorMessage &&
                <Typography className='overview-page__new-reseach--error-message'>{errorMessage}</Typography>
              }
              <Button className='overview-page__new-reseach--button' type='submit' variant='contained' color='secondary' disabled={isSubmitting}>Send Invite</Button>
            </div>
          </Form>
        )}
      </Formik>
    </DashboardContent>
  );
}
