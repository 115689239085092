import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Collapse,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';


const DrawerItem = {
  NONE: -1,
  OVERVIEW: 0,
  PATIENTS: 1,
  RESEARCHERS: 2,
  RESEARCHER_LIST: '2-0',
  ADD_RESEARCHER: '2-1',
  DATA: 3,
  LOGOUT: 4,
}

const links = [
  {
    text: 'Overview',
    url: '/dashboard/overview',
    id: DrawerItem.OVERVIEW,
  },
  {
    text: 'Participants',
    url: '/dashboard/participants',
    id: DrawerItem.PATIENTS,
  },
  {
    text: 'Researchers',
    adminOnly: true,
    id: DrawerItem.RESEARCHERS,
    subLinks: [
      {
        text: 'Researcher List',
        url: '/dashboard/researchers',
        adminOnly: true,
        id: DrawerItem.RESEARCHER_LIST,
      },
      {
        text: 'New Researcher',
        url: '/dashboard/researcher/add/',
        adminOnly: true,
        id: DrawerItem.ADD_RESEARCHER,
      },
    ],
  },
  {
    text: 'Data',
    url: '/dashboard/data',
    adminOnly: true,
    id: DrawerItem.DATA,
  },
  {
    text: 'Logout',
    id: DrawerItem.LOGOUT,
  },
];

function DashboardDrawer() {
  const { user } = useSelector(state => state.auth);
  const location = useLocation();
  const [researcheropen, setResearcherOpen] = React.useState(true);
  const [currentSelection, setCurrentSelection] = useState(DrawerItem.OVERVIEW);
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === '/dashboard/overview') {
     setCurrentSelection(DrawerItem.OVERVIEW);
    } else if (location.pathname === '/dashboard/participants') {
      setCurrentSelection(DrawerItem.PATIENTS);
    } else if (location.pathname === '/account/logout') {
      setCurrentSelection(DrawerItem.LOGOUT);
    } else if (location.pathname === '/dashboard/researchers') {
      setCurrentSelection(DrawerItem.RESEARCHER_LIST);
    } else if (location.pathname === '/dashboard/add-researcher') {
      setCurrentSelection(DrawerItem.ADD_RESEARCHER);
    } else if (location.pathname === '/dashboard/data') {
      setCurrentSelection(DrawerItem.DATA);
    }
  }, [location]);

  useEffect(() => {
    if (currentSelection !== DrawerItem.LOGOUT) {
      return;
    }

    const shouldLogout = confirm('Are you sure you want to logout?'); // eslint-disable-line  no-restricted-globals
    if (shouldLogout) {
      history.push('/account/logout');
    }
  }, [currentSelection]);

  const handleSubItemOnClick = (id) => {
    setResearcherOpen(!researcheropen);
    setCurrentSelection(id);
  }

  const listItemBlock = (link, id) => {
    if (!link.subLinks) {
      return (
        <ListItem
          button
          key={id}
          component={Link}
          to={link.url}
          disabled={link.adminOnly ? user.role !== 'ADMIN' : false}
          onClick={() => (setCurrentSelection(id))}
          selected={currentSelection === id}
        >
          <ListItemText primary={link.text} />
        </ListItem>
      );
    } else {
      return (
        <div
          className='dashboard__drawer--parent-link'
          key={id}
        >
          <ListItem
            button
            onClick={() => (handleSubItemOnClick(id))}
            disabled={link.adminOnly ? user.role !== 'ADMIN' : false}
            selected={currentSelection === id}
          >
            <ListItemText primary={link.text} />
            {researcheropen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={researcheropen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {link.subLinks.map((subLink, subId) => (
                listItemBlock(subLink, `${id}-${subId}`)
              ))}
            </List>
          </Collapse>
        </div>
      );
    }
  }

  return (
    <Drawer
      className="dashboard__drawer"
      variant="permanent"
    >
      <Toolbar />
      <List>
        {links.map((link) => (
          listItemBlock(link, link.id)
        ))}
      </List>
    </Drawer>
  );
}

export default DashboardDrawer;
