import React, { useEffect, useState } from 'react';
import DashboardContent from '../../components/DashboardContent';
import WellnessCheckCardContent from '../../components/WellnessCheckCardContent'
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import { getWellnessCheckProfileById } from '../../services/heartPainApi/patientService';

const WellnessCheck = () => {
  const { wellnessCheckId } = useParams();
  const [wellnessCheckProfile, setWellnessCheckProfile] = useState({});

  useEffect(() => {
    (async () => {
      const wellnessCheckData = await getWellnessCheckProfileById(wellnessCheckId);
      setWellnessCheckProfile(wellnessCheckData);
    })()
  }, [wellnessCheckId]);

  return(
    <DashboardContent title="Wellness Check Profile">
      <br/>
      <Card className='card' variant="outlined">
        <CardContent>
          <Typography variant='h5' className='eventprofile__header'><strong>Wellness Check Profile</strong></Typography>
          <WellnessCheckCardContent wellnessCheckData={wellnessCheckProfile.data} createdAt={wellnessCheckProfile.date} />
        </CardContent>
      </Card>
    </DashboardContent>
  )
}

export default WellnessCheck;
