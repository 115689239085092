import {
  addNewResearcher,
  newResearcherRegister,
  getResearchers,
  performAdminAction,
} from '../../services/heartPainApi/admin';

export const NEW_RESEARCHER_ERROR = 'NEW_RESEARCHER_ERROR';
export const NEW_REGISTER_RESEARCHER_ERROR = 'NEW_REGISTER_RESEARCHER_ERROR';
export const GET_RESEARCHERS = 'GET_RESEARCHERS';
export const ADMIN_OPTION_ERROR = 'ADMIN_OPTION_ERROR';
export const ADMIN_OPTION_UPDATE = 'ADMIN_OPTION_UPDATE';

export function loadResearchers() {
  return async dispatch => {
    const payload = await getResearchers();
    dispatch({ type: GET_RESEARCHERS, payload });
  };
}

export function newResearcherAction(newUserData) {
  return async dispatch => {
    const { error } = await addNewResearcher(newUserData);
    if (error) {
      dispatch({ type: NEW_RESEARCHER_ERROR, error });
      return error;
    }
  };
}

export function registerResearcherAction(password, token) {
  return async dispatch => {
    const { error } = await newResearcherRegister(password, token);
    if (error) {
      dispatch({ type: NEW_REGISTER_RESEARCHER_ERROR, error });
      return error;
    } else {
      return null;
    }
  };
}

export function ResearcherOption(adminOption) {
  return async dispatch => {
    const { data, error } = await performAdminAction(adminOption);
    if ( error ) {
      dispatch({ type: ADMIN_OPTION_ERROR, error: error });
      return error;
    } else if ( data ) {
      dispatch({ type: ADMIN_OPTION_UPDATE, data });
      return null;
    }
  }
}