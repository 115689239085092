import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Grid,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import DashboardContent from '../../components/DashboardContent';
import OverviewStatusCard from '../../components/OverviewStatusCard';
import { loadOverview } from '../../redux/actions/overviewActions';

import { getActivePatientsByDate } from '../../services/heartPainApi/patientService';

export default function Overview() {
  const dispatch = useDispatch();
  const {
    registered,
    articles_favourited,
    recent_active_patients,
    recent_heart_checked,
    latest_green_heart_check_status,
    latest_yellow_heart_check_status,
    latest_red_heart_check_status,
  } = useSelector(state => state.overview);
  const [graphDataSet, setGraphDataSet] = useState([]);
  const date = moment().format('YYYY-MM-DD');

  useEffect(() => {
    dispatch(loadOverview());
    (async () => {
      const activePatientData = await getActivePatientsByDate(date);
      const { graphData } = activePatientData;
      setGraphDataSet(graphData);
    })()
  }, []);

  const graphData = {
    datasets: [{
      label: 'Active Users',
      borderColor: 'rgb(255, 99, 132)',
      data: graphDataSet
    }]
  }

  const graphOption = {
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: 'week'
        }
      }],
      yAxes: [{
        ticks: {
          stepSize: 1,
        },
      }],
    },
    maintainAspectRatio: false,
  }

  return (
    <DashboardContent title="Overview" className="overview-page">
      <Grid container>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className="overview-page__value-list"
        >
          <OverviewStatusCard value={registered.value} text={registered.text} />
          {/*<OverviewStatusCard value={articles_favourited.value} text={articles_favourited.text} />
          <OverviewStatusCard value={recent_active_patients.value} text={recent_active_patients.text} />
          <OverviewStatusCard value={recent_heart_checked.value} text={recent_heart_checked.text} />
          <OverviewStatusCard value={latest_green_heart_check_status.value} text={latest_green_heart_check_status.text} />
          <OverviewStatusCard value={latest_yellow_heart_check_status.value} text={latest_yellow_heart_check_status.text} />
          <OverviewStatusCard value={latest_red_heart_check_status.value} text={latest_red_heart_check_status.text} />*/}
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Typography className='overview__card-title' variant='h5'>
                Daily Active Patients
              </Typography>
              <Line data={graphData} options={graphOption} height={240} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DashboardContent>
  );
}
