import {
  NEW_RESEARCHER_ERROR,
  NEW_REGISTER_RESEARCHER_ERROR,
  GET_RESEARCHERS,
  ADMIN_OPTION_ERROR,
  ADMIN_OPTION_UPDATE,
} from '../actions/adminActions';

const initalState = {
  errorMsg: null,
  list: [],
  columns: [],
  total: 0,
};

const adminReducer = (state = initalState, action) => {
  switch (action.type) {
    case NEW_RESEARCHER_ERROR:
    case NEW_REGISTER_RESEARCHER_ERROR:
    case ADMIN_OPTION_ERROR:
      return {
        ...state,
        errorMsg: action.error,
      };
    case GET_RESEARCHERS:
    case ADMIN_OPTION_UPDATE:
      const { researchers, columns, total } = action.payload;
      return { ...state, list: researchers, columns, total };
    default:
      return { ...state };
  }
};

export default adminReducer;
