import { api } from './api';
import getQueryString from '../../util/getQueryString';

async function getPatients(query) {
  const queryString = getQueryString(query);
  const { data, status } = await api.get(`/researcher/patients${queryString}`);
  if (status !== 200) {
    return null;
  }

  return data;
}

async function getPatientDataById(id, user) {
  const { data, status } = await api.get(`/researcher/patient/${id}`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function deletePatientDataById(id, user) {
  const { data, status } = await api.delete(`/researcher/patient/${id}`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getAllPatientsList(user) {
  const { data, status } = await api.get('/researcher/patients/csv-data', user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getPatientsCSVByStudyId(id, user) {
  const { data, status } = await api.get(`/researcher/patient/csv-data/${id}`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getActivePatientsByDate(date, user) {
  const { data, status } = await api.get(`/researcher/patients/active?targetEndDate=${date}`, user);
  if (status !== 200) {
    return null;
  }

  return data;
}

async function getPatientHeartChecksByStudyId(id, pageData) {
  const queryString = getQueryString(pageData);
  const { data, status } = await api.get(`/researcher/patient/${id}/heart-check${queryString}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getPatientWellnessChecksByStudyId(id, pageData) {
  const queryString = getQueryString(pageData);
  const { data, status } = await api.get(`/researcher/patient/${id}/wellness-check/${queryString}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getPatientNotesByStudyId(id, pageData) {
  const queryString = getQueryString(pageData);
  const { data, status } = await api.get(`/researcher/patient/${id}/notes/${queryString}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getHeartCheckProfileById(id) {
  const { data, status } = await api.get(`/researcher/heart-check/${id}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getWellnessCheckProfileById(id) {
  const { data, status } = await api.get(`/researcher/wellness-check/${id}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function addPatientByStudyId(id) {
  try {
    const { status } = await api.post(`/researcher/patients/add/${id}`);
    return true;
  } catch (error) {
    return false;
  }
}

async function activatePatientById(id) {
  const { data, status } = await api.post(`/admin/patient/${id}/activate`);
  return status === 200;
}

async function deactivatePatientById(id) {
  const { data, status } = await api.post(`/admin/patient/${id}/deactivate`);
  return status === 200;
}

export {
  getPatients,
  getPatientDataById,
  deletePatientDataById,
  getAllPatientsList,
  getPatientsCSVByStudyId,
  getActivePatientsByDate,
  getPatientHeartChecksByStudyId,
  getPatientWellnessChecksByStudyId,
  getPatientNotesByStudyId,
  getHeartCheckProfileById,
  getWellnessCheckProfileById,
  addPatientByStudyId,
  activatePatientById,
  deactivatePatientById,
};
