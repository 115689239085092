import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { authCheckAction } from '../redux/actions/authActions';

const UnprotectedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const [authDetermined, setAuthDetermined] = React.useState(isAuthenticated);

  React.useEffect(() => {
    if (!isAuthenticated) {
      dispatch(authCheckAction(() => setAuthDetermined(true)));
    }
  }, [isAuthenticated, dispatch]); // eslint-disable-line

  return (
    authDetermined ? (
      <Route
        {...rest}
        render={props => {
          if (!isAuthenticated) {
            return <Component {...props} />;
          }
          return (
            <Redirect to={
              {
                pathname: '/dashboard/overview',
              }
            }
            />
          );
        }}
      />
    ) : null
  );
};

export default UnprotectedRoute;
