import React, { useState } from 'react';
import {
  Button,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { addPatientByStudyId } from '../../services/heartPainApi/patientService';
import DashboardContent from '../../components/DashboardContent';

const ForgetSchema = Yup.object().shape({
  studyId: Yup.string()
    .length(5, 'Study ID must be 5 characters long')
    .matches(/^[0-9]*$/g, 'Study ID can only have numbers')
    .required('Required'),
});

const initialValues = {
  studyId: '',
};

export default function AddPatients() {
  const [isSuccessful, setIsSuccessful] = useState();
  const [successId, setSuccessId] = useState();

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const { studyId } = values;
    const result = await addPatientByStudyId(studyId);
    if (result) {
      setIsSuccessful(true);
      alert(`You've successfully added study id ${studyId}. You may continue adding more study as needed.`);
      setSuccessId(studyId);
      resetForm();
    } else {
      setIsSuccessful(false);
    }
    setSubmitting(false);
  };

  return (
    <DashboardContent title="Add New Patient" className="overview-page">
      <Formik
        initialValues={initialValues}
        validationSchema={ForgetSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, resetForm }) => (
          <Form className='overview-page__new-reseach--form'>
            <Field
              className='overview-page__new-reseach--input'
              component={TextField}
              name='studyId'
              label='New Study ID'
            />
            <div className='overview-page__new-reseach--submit'>
              <Button className='overview-page__new-reseach--button' type='submit' variant='contained' color='secondary' disabled={isSubmitting}>Submit</Button>
              {(typeof(isSuccessful) !== "undefined") &&
                <Typography className={isSuccessful ?  'overview-page__new-reseach--success-message': 'overview-page__new-reseach--error-message'}>
                  {isSuccessful ? 
                    `You've successfully added study id ${successId}. You may continue adding more study as needed.`
                  :
                    'Study ID already in use.'
                  }
                </Typography>
              }
            </div>
          </Form>
        )}
      </Formik>
    </DashboardContent>
  );
}
