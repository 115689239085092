import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import moment from 'moment';

const HeartCheckStatusHeader = ({ status, createdAt }) => (
  <>
    <Typography>
      <strong>Current Heart Check Status:</strong>&nbsp;
      {status === 'green' &&
        <span className='green-text'>
          Green
        </span>
      }
      {status === 'yellow' &&
        <span className="yellow-text">
          Yellow
        </span>
      }
      {status === 'red' &&
        <span className='red-text'>
          Red
        </span>
      }
    </Typography>
    <Typography>
      <strong>Updated At:</strong> {createdAt ? moment(createdAt).format('MMMM Do YYYY, h:mm:ss a') : ''}
    </Typography>
    <br/>
  </>
)

export default HeartCheckStatusHeader;
