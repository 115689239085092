import {
  AUTH_AUTHENTICATED_CHECK,
  AUTH_LOGIN_USER,
  AUTH_LOGOUT,
  AUTH_LOGIN_ERROR,
} from '../actions/authActions';


const defaultState = {
  user: null,
  isAuthenticated: false,
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_USER:
      return { 
        ...state,
        isAuthenticated: true,
        user: {...action.user},
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    case AUTH_AUTHENTICATED_CHECK:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        user: {...action.user},
      };
    case AUTH_LOGIN_ERROR:
      return { ...state,
        isAuthenticated: false,
        errorMessage: action.error,
      };
    default:
      return { ...state };
  }
};

export default authReducer;
