import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';

import ProtectedRoute from './components/ProtectedRoute';
import UnprotectedRoute from './components/UnprotectedRoute';
import store from './redux/store';
import theme from './theme';
import DashboardPage from './pages/Dashboard';
import AuthPage from './pages/Auth';

export default function App() {
  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route path="/account" component={AuthPage} />
              <ProtectedRoute path="/dashboard" component={DashboardPage} />

              {/* TODO: 404 page */}
              <Route render={() => <Redirect to='/account/login' />} />
            </Switch>
          </Router>
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  );
}
