import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Overview from './Overview';
import Patients from './Patients';
import Patient from './Patient';
import AddPatients from './AddPatients'

import HeartCheck from './HeartCheck';
import WellnessCheck from './WellnessCheck';

import NewResearcher from './NewResearcher';
import NewResearcherSuccess from './NewResearcherSuccess';
import Researchers from './Researchers';
import Data from './Data';

import DashboardDrawer from '../../components/DashboardDrawer';
import MainTemplate from '../../templates/MainTemplate';
import ProtectedRoute from '../../components/ProtectedRoute';

const Dashboard = () => (
  <MainTemplate id="dashboard" title="at heart">
    <div className="dashboard__container">
      <DashboardDrawer />
      <main id="main" className="dashboard__content">
        <Switch>
          <ProtectedRoute path="/dashboard/overview" component={Overview} />
          <ProtectedRoute path="/dashboard/participants/add" component={AddPatients} />
          <ProtectedRoute path="/dashboard/participants" component={Patients} />
          <ProtectedRoute path="/dashboard/participant/:studyId" component={Patient} />
          <ProtectedRoute path="/dashboard/researchers" component={Researchers} adminOnly />
          <ProtectedRoute path="/dashboard/researcher/add/success" component={NewResearcherSuccess} />
          <ProtectedRoute path="/dashboard/researcher/add/" component={NewResearcher} />
          <ProtectedRoute path="/dashboard/data/" component={Data} adminOnly />
          <ProtectedRoute path="/dashboard/heart-check/:heartCheckId" component={HeartCheck} />
          <ProtectedRoute path="/dashboard/wellness-check/:wellnessCheckId" component={WellnessCheck} />
          <Route render={() => <Redirect to="/dashboard/overview" />} />
        </Switch>
      </main>
    </div>
  </MainTemplate>
);

export default Dashboard;
